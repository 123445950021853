<template>
  <div id="game-page">
    <game-nav />
    <img class="logo-inner" src="/assets/logo_inner.png" alt="" />
    <wheel
      v-if="prizes.length"
      :prizes="prizes"
      ref="wheel"
      @win="finishDraw"
    />
    <lights v-if="prizes.length" ref="lights" />
    <button v-if="player" id="rotateBtn" @click="spin">
      <span>Крутить колесо</span><i></i>
    </button>
    <registration v-if="registration" :prizes="prizes" @submit="prepareGame" />
    <signature-window v-if="signatureWindow" />
    <div id="winFilter" ref="w1"></div>
    <div id="winImage" ref="w2"></div>
    <div id="win">
      <win
        :player="player"
        ref="winScreen"
        :prize="result"
        @restart="restart"
      />
    </div>
  </div>
</template>
<script>
import transport from "../../mixins/transport.js";
import GameNav from "./Nav.vue";
import Wheel from "./Wheel.vue";
import Registration from "./Registration.vue";
import Win from "./Win.vue";
import SignatureWindow from "./Signature/Window.vue";
import Lights from "./Lights.vue";

export default {
  name: "GameView",
  components: { Lights, SignatureWindow, Win, Registration, Wheel, GameNav },
  mixins: [transport],
  data() {
    return {
      prizes: [],
      // prizes: [
      //   {
      //     id: 1,
      //     icon: "/storage/prizes/BfNA4vKI9M3obGAFBVdLYEePMDXJfI4o6ibPkgDo.png",
      //     name: "Баночка светлого",
      //     tn: "/storage/prizes/JricGAOsTHtZrQecV96Ua7AVDAZzodEGmryizjoo.png",
      //     rank: 1,
      //   },
      //   {
      //     id: 1,
      //     icon: "/storage/prizes/br9hdtM5ZZYzYdL9ZOfBVyzvz0MXbzbCghrZShSj.png",
      //     name: "Банный набор",
      //     tn: "/storage/prizes/pZNLsXilPrww5Axpa9iIl282giBk2iMFcNOszhn5.png",
      //     rank: 1,
      //   },
      //   {
      //     id: 1,
      //     icon: "/storage/prizes/dYL4FUQMzmGpHL2LyFpUPa0vpIODFsZBvdH39vDY.png",
      //     name: "футболка поло",
      //     tn: "/storage/prizes/JK1bBYBn0HBb2gaFsiVvmKN19fmShGVCiDPO7bex.png",
      //     rank: 1,
      //   },
      //   {
      //     id: 1,
      //     icon: "/storage/prizes/F4ver80xtogWiFnGNsxX4AxTkpV5G6tXoLSMmQ7d.png",
      //     name: "Шоппер",
      //     tn: "/storage/prizes/ukT8WtBLvQwarW1J78YVhPKDGHBJCpEjMyGIMveS.png",
      //     rank: 1,
      //   },
      //   {
      //     id: 1,
      //     icon: "/storage/prizes/GIR6WbAlyDmloUX3dO9UBZQLP1xdmjafRJ3iKyqo.png",
      //     name: "Открывалка",
      //     tn: "/storage/prizes/zw8u0Hko6guFlLgxJI68nvONo6NQtm3rfIBj4tbA.png",
      //     rank: 1,
      //   },
      //   {
      //     id: 1,
      //     icon: "/storage/prizes/zIC21np72qhep2GJZriJCOlBy6PTVPLjnkaJZ9lx.png",
      //     name: "Набор для барбекю",
      //     tn: "/storage/prizes/L6TbD9COhv8seKUdPTC6LBUXBhM66ouCHvRZsFc2.png",
      //     rank: 1,
      //   },
      // ],
      player: null,
      // player: {
      //   name: "null",
      //   surname: "null",
      //   phone: "null",
      //   birthdate: "null",
      //   gender: "f",
      // },
      spinsLeft: 3,
      result: null,
      signatureWindow: false,
    };
  },
  computed: {
    registration() {
      return !this.player && this.prizes.length;
    },
    canSpin() {
      return this.player && this.spinsLeft;
    },
  },
  created() {
    this.loadPrizes();
    window.addEventListener("signatureOpen", () => {
      this.signatureWindow = true;
    });
    window.addEventListener("signatureReceive", () => {
      this.signatureWindow = false;
    });
  },
  methods: {
    spin() {
      if (!this.canSpin) return;
      this.$refs.wheel.reset();
      this.$refs.wheel.spin();
      this.$refs.lights.spin();
      this.spinsLeft--;
    },
    checkRegistration(data) {
      return new Promise((resolve, reject) => {
        this.transport(
          "POST",
          "promoter/check-registration",
          data,
          resolve,
          reject
        );
      });
    },
    prepareGame(playerData) {
      this.checkRegistration(playerData)
        .then(this.getPrizes.bind(this))
        .then(() => {
          this.player = playerData;
          this.restartGame();
        })
        .catch((err) => {
          if (err?.type === "self") {
            if (err.reason === "prizesLength") {
              window.alert(
                "Нет активной доступных призов.\n Обратитесь к своему супервайзеру"
              );
            } else alert("Unknown reason");
          } else alert(this.error);
        });
    },
    restartGame() {
      this.spinsLeft = 3;
    },
    finishDraw(winPrize) {
      this.registerDraw(winPrize)
        .then(() => {
          this.result = winPrize;
          this.$refs.winScreen.win();
        })
        .catch(() => {
          alert(this.error);
        });
    },
    restart() {
      this.player = null;
      this.$refs.wheel.reset();
    },
    getPrizes() {
      return new Promise((resolve, reject) => {
        this.transport("GET", "promoter/prizes", {}, (response) => {
          this.prizes = response.data;
          if (!this.prizes.length) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ type: "self", reason: "prizesLength" });
          } else resolve(this.prizes);
        });
      });
    },
    loadPrizes() {
      this.getPrizes().catch((err) => {
        if (err?.type === "self") {
          if (err.reason === "prizesLength") {
            if (
              window.confirm(
                "Нет активной сесси или доступных призов.\n Нажмите ОК для перезагрузки или Отмена для выхода"
              )
            ) {
              this.loadPrizes();
            } else {
              this.$router.push({ name: "Logout" });
            }
          } else alert("Unknown reason");
        } else alert(err?.message);
      });
    },
    registerDraw(winPrize) {
      return new Promise((resolve, reject) => {
        this.transport(
          "POST",
          "promoter/draw",
          {
            prizes: this.prizes,
            prize: winPrize.id,
            participant: this.player,
          },
          resolve,
          reject
        );
      });
    },
  },
};
</script>
<style lang="scss">
#game-page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  min-height: 700px;
  overflow: hidden;
  width: 100%;

  background: #825619 url("/assets/bg2.jpg") no-repeat fixed top center;
  background-size: cover;

  @media (max-width: 768px) {
    background-image: url("/assets/bgm.jpg");
  }

  .logo-inner {
    position: absolute;
    top: 96px;
    left: 60px;
    width: 213px;

    @media (max-width: 768px) {
      width: 100px;
      top: 70px;
      left: calc(50% - 50px);
    }
  }
}

#rotateBtn {
  position: absolute;
  left: 60px;
  bottom: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 272px;
  height: 52px;
  border: none;
  border-radius: 24px;
  background: transparent;

  &::before {
    display: block;
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    height: 52px;
    border: 1px solid var(--black-40, #9b9ea5);
    border-radius: 24px;
    background: radial-gradient(
        78.38% 72.79% at 50% 50%,
        #fae8b8 0%,
        #ffc425 51%,
        #f89600 100%
      ),
      #ffc425;
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    height: 54px;
    bottom: -5px;
    border-radius: 24px;
    background: var(--Black, #060d1e);
  }

  span {
    color: var(--black-100, #000);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 137.5%;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
  }

  i {
    background-image: url("data:image/svg+xml,%3Csvg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='vaadin:arrow-forward' clip-path='url(%23clip0_1_177)'%3E%3Cpath id='Vector' d='M28.5 14.0052L18 5.43018V10.6802H14.5C0.5 10.6802 0.5 24.6802 0.5 24.6802C0.5 24.6802 2.25 17.6802 14.15 17.6802H18V22.7552L28.5 14.0052Z' fill='%23060D1E'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1_177'%3E%3Crect width='28' height='28' fill='white' transform='translate(0.5 0.180176)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: contain;
    width: 28px;
    height: 26px;
    position: relative;
    z-index: 3;
  }

  &:active {
    &::before,
    span,
    i {
      transform: translateY(3px);
    }
  }

  @media (max-width: 768px) {
    left: calc(50% - 272px / 2);
    top: 250px;
    bottom: auto;
    i {
      transform: rotate(90deg);
      margin-left: 8px;
    }
    &:active {
      i {
        transform: translateY(3px) rotate(90deg);
      }
    }
  }
}

#winFilter {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0px);
  z-index: 100;
  opacity: 0;
  transition: all 0.4s ease;
  pointer-events: none;

  &.stage1,
  &.stage2 {
    backdrop-filter: blur(6px);
    opacity: 1;
  }
}

#winImage {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: -300px;
  background: url("/assets/grats-h.jpg") no-repeat center / cover;
  z-index: 101;
  mix-blend-mode: lighten;
  opacity: 0;
  transform: translateY(-300px);
  transition: all 3s cubic-bezier(0.02, 0.68, 0.22, 1.01);
  pointer-events: none;

  &.stage1 {
    opacity: 1;
    transform: translateY(0);
  }

  &.stage2 {
    opacity: 0;
    transform: translateY(0);
    transition-duration: 0.4s;
  }
}
</style>
