<template>
  <div></div>
</template>

<script>
import transport from "../../mixins/transport";
import axios from "axios";

export default {
  name: "Logout",
  mixins: [transport],
  created() {
    axios.post("/api/logout").then(() => {
      this.$router.push({ name: "Login" });
    });
  },
};
</script>
<style scoped lang="scss">
body {
  background: #f1e6be url("/assets/bg1.svg") no-repeat fixed top center;
  background-size: cover;
}
</style>
