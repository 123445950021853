export default {
  data() {
    return {
      rotation: 0,
      speed: 0,
      slowDownPerMs: 0.999,
      stopThreshold: 0.0015,
      // stopThreshold: 0.0017,
      prevTS: null,
      oneSpinSpeedUpPerMs: 0.2,
      // oneSpinSpeedUpPerMs: 0.01,
      // oneSpinSpeedUpPerMs: 0.015,
      afRequested: null,
    };
  },
  computed: {
    rotateCSS() {
      return {
        transform: "rotate(" + this.rotation + "deg)",
      };
    },
  },
  methods: {
    spin() {
      this.speed += this.oneSpinSpeedUpPerMs;
      this.checkAnimation();
    },
    checkAnimation() {
      if (this.speed <= this.stopThreshold) {
        window.cancelAnimationFrame(this.afRequested);
        this.afRequested = null;
        return;
      }
      if (this.afRequested) return;
      this.afRequested = window.requestAnimationFrame(
        this.animation.bind(this)
      );
      this.prevTS = document.timeline.currentTime;
    },
    calcSpeed(delta) {
      this.speed = this.speed * Math.pow(this.slowDownPerMs, delta - 1);
    },
    animation(ts) {
      if (!this.prevTS) {
        this.afRequested = window.requestAnimationFrame(
          this.animation.bind(this)
        );
        this.prevTS = ts;
        return;
      }
      const delta = ts - this.prevTS;
      this.rotation += delta * this.speed;
      this.calcSpeed(delta);
      this.prevTS = ts;
      if (this.speed > this.stopThreshold) {
        this.afRequested = window.requestAnimationFrame(
          this.animation.bind(this)
        );
      } else {
        this.afRequested = null;
        if (typeof this.stopWheel === "function") this.stopWheel();
      }
    },
    // stopWheel() {},
  },
};
