<script>
import SignatureReceiver from "./Receiver.vue";

export default {
  name: "SignatureWindow",
  components: { SignatureReceiver },
  data() {
    return {
      stageClass: null,
      canvas: null,
      context: null,
      clickX: [],
      clickY: [],
      clickDrag: [],
      paint: null,
      offset: {
        left: null,
        top: null,
      },
    };
  },
  mounted() {
    this.reveal().then(() => {
      setTimeout(this.initCanvas.bind(this), 400);
    });
  },
  methods: {
    reveal() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.stageClass = "-active";
          resolve();
        }, 100);
      });
    },
    off() {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.stageClass = null;
          resolve();
        }, 200);
      });
    },
    initCanvas() {
      this.canvas = this.$refs.c;
      const rect = this.canvas.getBoundingClientRect();
      this.canvas.width = rect.width;
      this.canvas.height = rect.height;
      this.context = this.canvas.getContext("2d");
      this.context.strokeStyle = "#525252";
      this.context.lineJoin = "round";
      this.context.lineWidth = 3;
      this.canvas.addEventListener("mousedown", this.mouseWins.bind(this));
      this.canvas.addEventListener("touchstart", this.touchWins.bind(this));
      this.offset.top = rect.top + window.scrollY;
      this.offset.left = rect.left + window.scrollX;
    },
    addClick(x, y, dragging) {
      this.clickX.push(x);
      this.clickY.push(y);
      this.clickDrag.push(dragging);
    },
    clear() {
      this.context.clearRect(
        0,
        0,
        this.context.canvas.width,
        this.context.canvas.height
      );
    },
    drawNew() {
      const i = this.clickX.length - 1;
      if (!this.clickDrag[i]) {
        if (!this.clickX.length) {
          this.context.beginPath();
          this.context.moveTo(this.clickX[i], this.clickY[i]);
          this.context.stroke();
        } else {
          this.context.closePath();

          this.context.beginPath();
          this.context.moveTo(this.clickX[i], this.clickY[i]);
          this.context.stroke();
        }
      } else {
        this.context.lineTo(this.clickX[i], this.clickY[i]);
        this.context.stroke();
      }
    },
    mouseDownEventHandler(e) {
      this.paint = true;
      const x = e.pageX - this.offset.left;
      const y = e.pageY - this.offset.top;
      if (this.paint) {
        this.addClick(x, y, false);
        this.drawNew();
      }
    },
    touchstartEventHandler(e) {
      this.paint = true;
      if (this.paint) {
        this.addClick(
          e.touches[0].pageX - this.offset.left,
          e.touches[0].pageY - this.offset.top,
          false
        );
        this.drawNew();
      }
    },
    mouseUpEventHandler() {
      this.context.closePath();
      this.paint = false;
    },
    mouseMoveEventHandler(e) {
      if (!this.paint) return;
      const x = e.pageX - this.offset.left;
      const y = e.pageY - this.offset.top;
      this.addClick(x, y, true);
      this.drawNew();
    },
    touchMoveEventHandler(e) {
      if (!this.paint) return;
      const x = e.touches[0].pageX - this.offset.left;
      const y = e.touches[0].pageY - this.offset.top;
      this.addClick(x, y, true);
      this.drawNew();
    },
    setUpHandler(isMouseAndNotTouch, detectEvent) {
      this.removeRaceHandlers();
      if (isMouseAndNotTouch) {
        this.canvas.addEventListener(
          "mouseup",
          this.mouseUpEventHandler.bind(this)
        );
        this.canvas.addEventListener(
          "mousemove",
          this.mouseMoveEventHandler.bind(this)
        );
        this.canvas.addEventListener(
          "mousedown",
          this.mouseDownEventHandler.bind(this)
        );
        this.mouseDownEventHandler(detectEvent);
      } else {
        this.canvas.addEventListener(
          "touchstart",
          this.touchstartEventHandler.bind(this)
        );
        this.canvas.addEventListener(
          "touchmove",
          this.touchMoveEventHandler.bind(this)
        );
        this.canvas.addEventListener(
          "touchend",
          this.mouseUpEventHandler.bind(this)
        );
        this.touchstartEventHandler(detectEvent);
      }
    },
    mouseWins(e) {
      this.setUpHandler(true, e);
    },
    touchWins(e) {
      this.setUpHandler(false, e);
    },
    removeRaceHandlers() {
      this.canvas.removeEventListener("mousedown", this.mouseWins);
      this.canvas.removeEventListener("touchstart", this.touchWins);
    },
    complete() {
      this.off().then(() => {
        const image = this.canvas.toDataURL();
        const event = new CustomEvent("signatureReceive", { detail: image });
        window.dispatchEvent(event);
      });
    },
  },
};
</script>

<template>
  <div id="sign" :class="stageClass">
    <div class="window bordered">
      <h1>Поставьте подпись</h1>
      <p class="t1">Автограф</p>
      <div class="place">
        <canvas ref="c"></canvas>
        <button type="button" @click.prevent="clear"></button>
      </div>
      <p class="agree">
        Ставя роспись, вы соглашаетесь с
        <a href="#">условиями&nbsp;использования</a>,<br />
        <a href="#">политикой&nbsp;конфиденциальности</a>.
      </p>
      <button type="button" class="yellowBtn" @click.prevent="complete">
        <span>ГОТОВО</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
#sign {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0px);
  opacity: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  padding: 10px;

  .window {
    padding: 32px;
    transform: scale(0.01);
    transition: all 0.4s ease-out;
    @media (max-width: 768px) {
      width: 100%;
      overflow: hidden;
      padding: 32px 16px;
    }

    h1 {
      margin: 0 0 32px;
    }

    p {
      margin: 8px 0;
    }

    .place {
      border-radius: 8px;
      background: var(--Bejev, #f1e6be);
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      width: 600px;
      aspect-ratio: 3/1;

      @media (max-width: 768px) {
        width: 100%;
      }

      canvas {
        width: 600px;
        aspect-ratio: 3/1;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      button {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        border-radius: 20px;
        border: none;
        &::before {
          display: block;
          content: "";
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border: 1px solid var(--black-40, #9b9ea5);
          border-radius: 20px;
          background-color: #ffc425;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='fa6-solid:eraser'%3E%3Cpath id='Vector' d='M10.0895 2.2937L2.39388 9.58433C1.56923 10.3656 1.56923 11.6312 2.39388 12.4125L5.03277 14.9125C5.4286 15.2875 5.96628 15.4968 6.52704 15.4968H17.3894C17.9732 15.4968 18.4449 15.05 18.4449 14.4968C18.4449 13.9437 17.9732 13.4968 17.3894 13.4968H13.2958L17.6071 9.41558C18.4317 8.63433 18.4317 7.3687 17.6071 6.58745L13.0781 2.2937C12.2534 1.51245 10.9175 1.51245 10.0928 2.2937H10.0895ZM10.3105 13.5H6.52374L3.88485 11L7.99822 7.10308L12.5305 11.3968L10.3105 13.5Z' fill='%23060D1E'/%3E%3C/g%3E%3C/svg%3E"),
            radial-gradient(
              78.38% 72.79% at 50% 50%,
              #fae8b8 0%,
              #ffc425 51%,
              #f89600 100%
            );
          background-repeat: no-repeat, no-repeat;
          background-position: center center;
          background-size: 24px auto, 100% 100%;
        }

        &::after {
          display: block;
          content: "";
          position: absolute;
          z-index: 1;
          left: 0;
          right: 0;
          width: 40px;
          height: 42px;
          bottom: -2px;
          border-radius: 20px;
          background: var(--Black, #060d1e);
        }

        &:active {
          transform: translateY(2px);
          &::after {
            bottom: 0;
            height: 40px;
          }
        }
      }
    }
  }

  &.-active {
    backdrop-filter: blur(6px);
    opacity: 1;
    .window {
      transform: scale(1);
    }
  }
}
</style>
