<!--suppress JSUnusedLocalSymbols -->
<script>
import rotor from "./rotor.js";

export default {
  name: "Lights",
  mixins: [rotor],
  methods: {
    stopWheel() {
      const fullSpins = Math.floor(this.rotation / 360);
      this.rotation -= fullSpins * 360;
    },
  },
};
</script>

<template>
  <div id="lights" :style="rotateCSS">
    <div class="_inner">
      <div class="_light" v-for="n in 30"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(6deg);
  }
}

#lights {
  --size: 762px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: 96px;
  left: 491px;
  mix-blend-mode: color-dodge;
  z-index: 10;
  pointer-events: none;
  opacity: 0.8;

  ._inner {
    width: var(--size);
    height: var(--size);
    position: absolute;
    left: 0;
    top: 0;
    animation: swing 4s steps(1) infinite;
  }

  ._light {
    width: 58px;
    height: 58px;
    position: absolute;
    left: -11px;
    top: calc(50% - 35px);
    transform-origin: calc(var(--size) / 2 + 10px) 34px;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 58px;
      height: 58px;
      display: block;
      content: "";
      background: url("/assets/wheel/light.png") center / contain no-repeat;
    }
    @for $i from 1 through 30 {
      &:nth-child(#{$i}) {
        transform: rotate(#{(12 * ($i - 1))}deg);
        &::before {
          transform: rotate(#{(-12 * ($i - 1))}deg) scale(1.5);
        }
      }
    }
  }

  @media (max-width: 768px) {
    --size: 558px;
    left: calc(50% - var(--size) / 2);
    top: auto;
    bottom: calc(50% - var(--size));
    transform-origin: center center;
    ._light {
      @for $i from 1 through 30 {
        &:nth-child(#{$i}) {
          transform: rotate(#{(12 * ($i - 1) + 7)}deg);
          &::before {
            transform: rotate(#{(-12 * ($i - 1))}deg) scale(1.5);
          }
        }
      }
    }
  }
}
</style>
