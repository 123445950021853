<script>
import IMask from "imask";

export default {
  name: "TheInput",
  props: {
    value: String,
    label: String,
    hint: String,
    rules: Array,
    maskOptions: Object,
  },
  data() {
    return {
      error: null,
      state: null,
      localVal: this.value,
      maskEl: null,
    };
  },
  computed: {
    hintVal() {
      return this.error ?? this.hint;
    },
  },
  mounted() {
    if (this.maskOptions) {
      this.maskEl = IMask(this.$refs.input, this.maskOptions);
    }
  },
  methods: {
    resetState() {
      this.state = null;
    },
    validate() {
      if (!this.rules) return true;
      const val = this.maskOptions ? this.maskEl.value : this.localVal;
      const pass = this.rules.every((rule) => {
        const res = rule(val);
        if (res !== true) {
          this.error = res;
          return false;
        }
        return res;
      });
      if (!pass) {
        this.state = "error";
        return false;
      } else {
        this.error = null;
        this.state = "success";
        return true;
      }
    },
    getValue() {
      const val = this.maskOptions ? this.maskEl.value : this.localVal;
      if (!this.rules) return val;
      const pass = this.rules.every((rule) => {
        const res = rule(val);
        if (res !== true) {
          this.error = res;
          return false;
        }
        return res;
      });
      if (!pass) {
        this.state = "error";
        return null;
      } else {
        this.error = null;
        this.state = "success";
        return val;
      }
    },
    change() {
      const val = this.getValue();
      if (val) this.$emit("input", val);
    },
  },
};
</script>

<template>
  <label :class="{ error: state === 'error', success: state === 'success' }">
    <span v-if="label" class="label hint">{{ label }}</span>
    <input
      v-model="localVal"
      class="t1"
      ref="input"
      @change="change"
      @input="resetState"
      autocomplete="off"
    />
    <span class="help">{{ hintVal }}&nbsp;</span>
  </label>
</template>

<style scoped lang="scss">
label {
  display: flex;
  flex-direction: column;
  .label {
    padding: 4px 0;
  }
  input {
    border-radius: 8px;
    border: 1px solid var(--black-40, #9b9ea5);
    padding: 8px 8px 8px 12px;
    font-weight: 400;
    width: 100%;
  }
  .help {
    padding: 3px 0 2px 12px;
  }
  &.error {
    .label {
      color: var(--Brand_Red, #c41230);
    }
    input {
      border-color: var(--Brand_Red, #c41230);
    }
    .help {
      color: var(--Brand_Red, #c41230);
    }
  }
  &.success {
    .label {
      color: var(--Green, #00502f);
    }
    input {
      border-color: var(--Green, #00502f);
    }
    .help {
      color: var(--Green, #00502f);
    }
  }
}
</style>
