<script>
import TheForm from "./TheForm.vue";
import Presents from "./Presents.vue";

export default {
  name: "Registration",
  components: { Presents, TheForm },
  props: {
    prizes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      openPrizes: false,
    };
  },
  methods: {
    submit(data) {
      this.$emit("submit", data);
    },
    prizesChange(value) {
      this.openPrizes = value;
    },
  },
};
</script>

<template>
  <div id="registration" :class="{ '-prizes': openPrizes }">
    <the-form @submit="submit" :class="{ '-prizes': openPrizes }" />
    <presents :prizes="prizes" @change="prizesChange" />
  </div>
</template>

<style scoped lang="scss">
#registration {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.26) 53%,
    rgba(0, 0, 0, 0.5) 100%
  );
  backdrop-filter: blur(6px);
  @media (max-width: 768px) {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    &.-prizes {
      overflow: hidden;
    }
  }
}
</style>
