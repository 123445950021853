<script>
export default {
  name: "Sector",
  props: {
    prize: {
      type: Object,
      required: true,
      icon: String,
      name: String,
      id: Number,
    },
  },
  data() {
    return {
      winCss: false,
    };
  },
  computed: {
    cssClass() {
      return {
        sector: true,
        "-selected": this.winCss,
        ["id" + this.prize.id]: true,
      };
    },
  },
  methods: {
    win() {
      this.winCss = true;
    },
    reset() {
      this.winCss = false;
    },
  },
};
</script>

<template>
  <div :class="cssClass">
    <div class="hl"></div>
    <p>{{ prize.name }}</p>
    <img :src="prize.icon" :alt="prize.name" />
  </div>
</template>

<style lang="scss">
.sector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url("/assets/wheel/green.png") center no-repeat;
  background-size: contain;
  width: calc(545px / 2);
  height: calc(398px / 2);
  padding: 0 5px 0 40px;
  position: absolute;
  transform-origin: -103px 97px;

  img {
    transform: rotate(90deg) translateY(20px);
    max-width: 120px;
    max-height: 100px;
    margin-left: -12px;
  }
  p {
    color: var(--White, #fff);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--Black, #060d1e);
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 108%; /* 17.28px */
    letter-spacing: -0.24px;
    text-transform: uppercase;
    position: relative;
    max-width: 119px;
    overflow: visible;
  }
  &:nth-child(odd) {
    background-image: url("/assets/wheel/yellow.png");

    //p {
    //  color: var(--Green, #00502f);
    //  -webkit-text-stroke-width: 0.3px;
    //  -webkit-text-stroke-color: var(--White, #fff);
    //}
  }

  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      transform: rotate(#{30deg * ($i - 1)});
      @media (max-width: 768px) {
        transform: rotate(#{30deg * ($i - 1)}) scale(0.726);
      }
    }
  }
  .hl {
    width: calc(729px / 2);
    height: calc(597px / 2);
    position: absolute;
    left: -32px;
    top: -51px;
    background: url("/assets/wheel/hl.png") center no-repeat;
    background-size: contain;
    display: none;
  }
  &.-selected {
    z-index: 1;
    .hl {
      display: block;
    }
  }
}
</style>
