<script>
const EMPTY =
  "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA";

export default {
  name: "SignatureReceiver",
  // props: {
  //   view: Object,
  // },
  data() {
    return {
      image: EMPTY,
    };
  },
  mounted() {
    window.removeEventListener("signatureReceive", this.receive.bind(this));
    window.addEventListener("signatureReceive", this.receive.bind(this));
  },
  methods: {
    open() {
      window.dispatchEvent(window.signatureEventOpen);
      // console.log(this.$router);
      // console.log(this.$route.matched[0].components.default);
    },
    receive(event) {
      this.image = event.detail;
    },
    reset() {
      this.image = EMPTY;
    },
  },
};
</script>

<template>
  <div id="receiver" @click="open">
    <img :src="image" alt="" ref="img" />
  </div>
</template>

<style scoped lang="scss">
#receiver {
  width: 100%;
  aspect-ratio: 3/1;
  border-radius: 8px;
  background: var(--Bejev, #f1e6be);
  margin-bottom: 8px;
  img {
    width: 100%;
    aspect-ratio: 3/1;
    object-fit: contain;
  }
}
</style>
