import transportRequest from "./transportFuncs/transportRequest";
import transportError from "./transportFuncs/transportError.js";

export default {
  mixins: [transportRequest, transportError],
  data() {
    return {
      loading: false,
      scheduled: false,
    };
  },
  methods: {
    transport(
      method,
      uri,
      data = {},
      successCallback = null,
      errorCallback = null,
      afterCallback = null,
      controller = null
    ) {
      return new Promise((resolve, reject) => {
        this.transportRequest(method, uri, data, controller)
          .then((response) => {
            if (typeof successCallback === "function") {
              successCallback(response);
            }
            resolve(response);
          })
          .catch((error) => {
            this.transportError(error, errorCallback);
            reject(error);
          })
          .finally(afterCallback);
      });
    },
    exchange(
      store,
      data,
      uri,
      method = "GET",
      successSnack = null,
      successCallback,
      scheduleIfBusy = false,
      errorCallback = null
    ) {
      if (this.loading) {
        if (scheduleIfBusy) this.scheduled = arguments;
        return;
      }
      this.loading = true;
      this.transport(
        method,
        uri,
        data,
        (response) => {
          store(response.data);
          if (successSnack) {
            this.successSnack(successSnack);
          }
          if (typeof successCallback === "function") {
            successCallback(response);
          }
        },
        (response) => {
          if (typeof errorCallback === "function") errorCallback(response);
        },
        () => {
          this.loading = false;
          if (this.scheduled) {
            const sc = [...this.scheduled];
            this.scheduled = false;
            this.exchange(...sc);
          }
        }
      );
    },
    exchangeController(store, uri, data = null, method = "POST", options = {}) {
      const zis = this;
      const returnObj = {
        loading: false,
        keepLoading: false,
        abortConcurrent: false,
        scheduleIfBusy: false,
        method,
        uri,
        data,
        store,
        successSnack: null,
        successCallback: null,
        errorCallback: null,
        scheduled: false,
        send(overrideData = {}) {
          return zis.exchangeControl(this, overrideData);
        },
        abortController: null,
        activeRequest: null,
      };
      return Object.assign(returnObj, options);
    },
    exchangeControl(controller, overrideData = {}) {
      if (controller.data !== null && typeof controller.data === "object") {
        Object.assign(controller.data, overrideData);
      } else {
        controller.data = overrideData;
      }
      if (controller.loading) {
        if (controller.abortConcurrent) {
          controller.activeRequest.finally(() => {
            controller.keepLoading = true;
          });
          controller.abortController.abort();
        } else if (controller.scheduleIfBusy) {
          controller.scheduled = true;
          return;
        }
      }
      controller.abortController = new AbortController();
      controller.loading = true;
      controller.activeRequest = this.transport(
        controller.method,
        controller.uri,
        controller.data,
        (response) => {
          controller.store(response.data);
          if (controller.successSnack) {
            this.successSnack(controller.successSnack);
          }
          if (typeof controller.successCallback === "function") {
            controller.successCallback(response);
          }
        },
        (response) => {
          if (typeof controller.errorCallback === "function")
            controller.errorCallback(response);
        },
        () => {
          if (!controller.keepLoading) controller.loading = false;
          else controller.keepLoading = false;
          if (controller.scheduled) {
            controller.scheduled = false;
            this.exchangeControl(controller);
          }
        },
        true,
        controller.abortController.signal
      );
      return controller.activeRequest;
    },
  },
};
