import Vue from "vue";
import VueRouter from "vue-router";

import Login from "./components/Auth/Login.vue";
import Logout from "./components/Auth/Logout.vue";
import GameView from "./components/Game/View.vue";

Vue.use(VueRouter);

const firstAvailable = () => {
  console.log("firstAvailable");
  const target = routes.find((route) => {
    return !route.meta?.notTarget;
  });
  console.log(target);
  return target ? target.path : "/";
};

export const routes = [
  {
    path: "/",
    redirect: firstAvailable,
    meta: {
      requiresAuth: true,
      notTarget: true,
    },
  },
  {
    path: "/promoter",
    redirect: firstAvailable,
    meta: {
      requiresAuth: true,
      notTarget: true,
    },
  },
  {
    path: "/promoter/login",
    name: "Login",
    component: Login,
    meta: {
      notTarget: true,
    },
  },
  {
    path: "/promoter/game",
    name: "Game",
    component: GameView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/promoter/logout",
    name: "Logout",
    component: Logout,
    meta: {
      notTarget: true,
    },
  },
];

// noinspection DuplicatedCode
const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!(window.username ?? false)) {
//       next({ path: "/promoter/login" });
//     } else next();
//   } else next();
// });

export default router;
