import axios from "axios";
import Vue from "vue";
import App from "./components/App.vue";
import router from "./router";

// window.logoutEvent = new Event("logout");
window.signatureEventOpen = new Event("signatureOpen");
window.signatureEventReceive = new Event("signatureReceive");

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

new Vue({
  router,
  render: (h) => h(App, { ref: "app" }),
}).$mount("#app");
