<script>
import Sector from "./Sector.vue";
import rotor from "./rotor.js";

export default {
  name: "Wheel",
  components: { Sector },
  mixins: [rotor],
  props: {
    prizes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      afRequested: null,
      winPosition: 150,
      sectorSize: 30,
      winSectorOffset: 5,
    };
  },
  computed: {
    filledPrizes() {
      const filled = [...this.prizes];
      if (filled.length < 12) {
        const quota = 12 - filled.length;
        const ranked = this.ranked(filled);
        const rankSum = this.rankSum(ranked);
        let quotaSpread = 0;
        let temp1;
        Object.keys(ranked).forEach((rank) => {
          temp1 = Math.floor(quota * (Number(rank) / rankSum));
          // temp1 = quota * (Number(rank) / rankSum);
          ranked[rank].quota = temp1;
          quotaSpread += temp1;
        });
        while (quotaSpread < quota) {
          for (let i = 6; i >= 1; i--) {
            if (!this.hasProp(ranked, i)) continue;
            ranked[i].quota++;
            quotaSpread++;
            if (quotaSpread >= quota) break;
          }
        }
        Object.keys(ranked).forEach((rank) => {
          for (let i = 0; i < ranked[rank].quota; i++) {
            filled.push(this.randomEl(ranked[rank].prizes));
          }
        });
      }
      this.shuffle(filled);
      return filled;
    },
  },
  methods: {
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    hasProp(object, propName) {
      return Object.prototype.hasOwnProperty.call(object, propName);
    },
    ranked(array) {
      const ranked = {};
      array.forEach((prize) => {
        const rank = 7 - prize.rank;
        if (!this.hasProp(ranked, rank))
          ranked[rank] = { quota: 0, prizes: [] };
        ranked[rank].prizes.push(prize);
      });
      return ranked;
    },
    rankSum(ranked) {
      return Object.keys(ranked).reduce((total, key) => total + Number(key), 0);
    },
    randomEl(array) {
      return array[Math.floor(Math.random() * array.length)];
    },
    stopWheel() {
      const fullSpins = Math.floor(this.rotation / 360);
      this.rotation -= fullSpins * 360;
      let winSector =
        this.winSectorOffset - Math.round(this.rotation / this.sectorSize);
      if (winSector < 0) winSector = 12 + winSector;
      this.$refs.sector[winSector].win();
      this.$emit("win", this.filledPrizes[winSector]);
    },
    reset() {
      this.$refs.sector.forEach((sector) => sector.reset());
    },
  },
};
</script>

<template>
  <div id="wheel">
    <div class="arrow-back"></div>
    <div class="base" :style="rotateCSS"></div>
    <div class="sectors" :style="rotateCSS">
      <sector v-for="prize in filledPrizes" :prize="prize" ref="sector" />
    </div>
    <div class="center">
      <img src="/assets/logo_w.svg" alt="Gus" />
    </div>
    <div class="shine"></div>
    <div class="arrow">
      <div class="ar"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#wheel {
  --size: 762px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: 96px;
  left: 491px;

  @media (max-width: 768px) {
    --size: 558px;
    left: calc(50% - var(--size) / 2);
    top: auto;
    bottom: calc(50% - var(--size));
    transform: rotate(116deg);
    transform-origin: center center;
  }

  .base {
    position: absolute;
    left: 0;
    top: 0;
    width: var(--size);
    height: var(--size);
  }

  .sectors {
    position: absolute;
    left: calc(50% + 103px);
    top: calc(50% - 97px);
    z-index: 2;
    transform-origin: -103px 97px;
    transform: rotate(15deg);
  }
  .center {
    width: 258px;
    height: 258px;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background: #00502f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    img {
      width: 197px;
    }
    @media (max-width: 768px) {
      transform: translate(-50%, -50%) scale(0.727) rotate(-116deg);
    }
  }
  .shine {
    position: absolute;
    left: 6px;
    top: 6px;
    width: 750px;
    height: 750px;
    background: url("/assets/wheel/shine.png") center no-repeat;
    background-size: auto 800px;
    mix-blend-mode: screen;
    z-index: 8;
    border-radius: 50%;
    opacity: 0.5;
    pointer-events: none;
    @media (max-width: 768px) {
      transform: scale(0.727);
    }
  }
  .arrow {
    width: 87px;
    height: 69px;
    position: absolute;
    z-index: 2;
    bottom: 156px;
    left: 13px;
    transform: rotate(-10.5deg);
    .ar {
      width: 87px;
      height: 69px;
      position: absolute;
      background: url("/assets/wheel/arrow.png") center no-repeat;
      left: 0;
      top: 0;
    }

    @media (max-width: 768px) {
      bottom: 121px;
      left: -7px;
      transform: rotate(-5deg);
    }
  }

  .arrow-back {
    width: 107px;
    height: 107px;
    position: absolute;
    bottom: 135px;
    left: -9px;
    background: url("/assets/wheel/ab.svg") center no-repeat;
    background-size: contain;

    @media (max-width: 768px) {
      bottom: 101px;
      left: -26px;
    }
  }
}
</style>
