<script>
export default {
  name: "Win",
  props: {
    player: Object,
    prize: Object,
  },
  data() {
    return {
      stage: 0,
    };
  },
  computed: {
    stageClass() {
      return "stage" + this.stage;
    },
  },
  methods: {
    win() {
      this.changeStage(0);
      setTimeout(() => {
        this.changeStage(1);
      }, 1000);
    },
    changeStage(stage) {
      this.stage = stage;
      this.$parent.$refs.w1.className = this.stageClass;
      this.$parent.$refs.w2.className = this.stageClass;
    },
    restart() {
      this.changeStage(3);
      setTimeout(() => {
        this.$emit("restart");
      }, 1000);
    },
  },
};
</script>

<template>
  <div>
    <div class="win-screen" :class="stageClass">
      <div id="win-msg" :class="['bordered', 'window', stageClass]">
        <img src="/assets/gus.png" alt="" class="gus" />
        <h2>
          Поздравляем Вас, <br />
          {{ player?.name }}!
        </h2>
        <h3>Ваш приз:</h3>
        <div class="prize" v-if="prize">
          <div class="tn">
            <img :src="prize.tn" alt="" />
          </div>
          <p>{{ prize?.name }}</p>
        </div>
        <button type="button" @click="changeStage(2)">
          <span>Выдать приз</span>
        </button>
      </div>
    </div>
    <div class="win-screen -second" :class="stageClass">
      <div id="qr-msg" :class="['bordered', 'window', stageClass]">
        <img src="/assets/ventil.png" alt="" class="ventil" />
        <div class="t1">
          Хорошечное настроение, новости и конкурсы <br />
          тоже хорошечные на нашей странице!
        </div>
        <div class="qr-place">
          <img src="/assets/link.svg" class="link" alt="" />
          <img src="/assets/qr.png" class="qr" alt="qr" />
        </div>
        <button type="button" @click="restart">
          <span>В начало</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.win-screen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 150;
  pointer-events: none;
  padding: 10px;
  &.stage1,
  &.stage2 {
    pointer-events: all;
    &.-second {
      pointer-events: none;
    }
  }
}
.window {
  width: 416px;
  background: #ffffff;
  padding: 48px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.5s cubic-bezier(0.26, 1.36, 0.65, 1);
  transform: translateY(-200vh);
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 40px;
    border: none;
    border-radius: 8px;
    background: transparent;
    &::before {
      display: block;
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      height: 40px;
      border: 1px solid var(--black-40, #9b9ea5);
      border-radius: 8px;
      background: radial-gradient(
          78.38% 72.79% at 50% 50%,
          #fae8b8 0%,
          #ffc425 51%,
          #f89600 100%
        ),
        #ffc425;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      height: 40px;
      bottom: -5px;
      border-radius: 8px;
      background: var(--Black, #060d1e);
    }
    span {
      color: var(--black-100, #000);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 800;
      line-height: 137.5%;
      text-transform: uppercase;
      position: relative;
      z-index: 3;
      transform: translateY(2px);
    }
    &:active {
      &::before,
      span,
      i {
        transform: translateY(3px);
      }
    }
    &:disabled {
      &::before {
        background: #ffe9af;
        border-color: var(--black-20, #cdcfd2);
      }
      &::after {
        background: var(--black-20);
      }
      span {
        color: var(--black-40);
      }
      &::before,
      span,
      i {
        transform: translateY(3px);
      }
    }
  }
}
#win-msg {
  &.stage1 {
    transform: translateY(0);
  }
  &.stage2 {
    transform: translateY(200vh);
  }
  &.stage3 {
    transition: none;
  }
  .gus {
    position: absolute;
    width: 233px;
    top: -118px;
    left: 93px;
    @media (max-width: 768px) {
      left: calc(50% - 118px);
    }
  }
  h2 {
    margin-bottom: 32px;
  }
  h3 {
    margin: 0 0 16px;
  }

  .prize {
    display: flex;
    flex-direction: column;
    width: 120px;
    justify-content: center;
    pointer-events: none;
    transition: opacity 0.4s ease;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      width: 240px;
    }

    .tn {
      background: linear-gradient(to bottom, #ffffff 0%, #a9a289 100%);
      padding: 3px;
      width: 120px;
      height: 120px;
      border-radius: 6px;
      margin-bottom: 4px;
      @media (max-width: 768px) {
        width: 240px;
        height: 240px;
      }
      img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    p {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 108%;
      letter-spacing: -0.24px;
    }
  }
}
#qr-msg {
  pointer-events: all;
  padding: 28px 32px 45px;

  .ventil {
    margin-bottom: 8px;
  }
  .t1 {
    margin-bottom: 16px;
  }
  .qr-place {
    border-radius: 8px;
    background: radial-gradient(
        78.38% 72.79% at 50% 50%,
        #fff 0%,
        #ffc425 51%,
        #f89600 100%
      ),
      #a8996e;
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    .link {
      margin-bottom: 20px;
    }
    .qr {
      width: 160px;
    }

    //.link {
    //  color: var(--White, #fff);
    //  text-align: center;
    //  font-size: 24px;
    //  font-style: normal;
    //  font-weight: 500;
    //  line-height: 108%;
    //  letter-spacing: -1px;
    //  text-transform: uppercase;
    //  display: flex;
    //  justify-content: space-between;
    //  gap: 8px;
    //  &::before {
    //    display: block;
    //    content: "";
    //    width: 24px;
    //    height: 24px;
    //    background: url("/assets/insta.svg") center / contain no-repeat;
    //    margin-top: -2px;
    //  }
    //}
  }

  &.stage2 {
    transform: translateY(0);
  }
  &.stage3 {
    transition: none;
  }
}
</style>
