<script>
import TheInput from "./TheInput.vue";
import SignatureReceiver from "./Signature/Receiver.vue";

export default {
  name: "TheForm",
  components: { SignatureReceiver, TheInput },
  data() {
    return {
      phoneMask: {
        mask: "+{7}(000)000-0000",
        lazy: false,
      },
      dateMask: {
        mask: "00 / 00 / 0000",
        lazy: false,
      },
      rules: {
        phone: [
          (v) => !!v.length || "Обязательное поле",
          (v) =>
            /^\+7\(7[0-9]{2}\)[0-9]{3}-[0-9]{4}$/.test(v) || "Неверный формат",
        ],
        date: [
          (v) => !!v.length || "Обязательное поле",
          (v) =>
            /^[0-3][0-9] \/ [01][0-9] \/ [12][9012][0-9]{2}$/.test(v) ||
            "Неверный формат",
          (v) => {
            const nums = v.split(" / ");
            const date = new Date(nums[2], Number(nums[1]) - 1, nums[0]);
            const now = new Date();
            if (date > now) return "Вы из будущего? :)";
            const max = new Date(
              now.getFullYear() - 21,
              now.getMonth(),
              now.getDate()
            );
            if (date > max) return "Вам ещё не исполнилось 21";
            return true;
          },
        ],
      },
      values: {
        name: null,
        surname: null,
        phone: null,
        birthdate: null,
        gender: "f",
      },
      canSave: false,
    };
  },
  watch: {
    values: {
      deep: true,
      handler() {
        this.canSave = this.validate();
      },
    },
  },
  methods: {
    validate() {
      let wasError = false;
      this.$children.forEach((input) => {
        if (!input.validate) return;
        if (!input.validate()) wasError = true;
      });
      return !wasError;
    },
    submit() {
      if (!this.validate()) return;
      this.values.signature = this.$refs.receiver.image;
      this.$emit("submit", this.values);
    },
  },
};
</script>

<template>
  <form id="form" class="bordered" autocomplete="off" @submit.prevent="submit">
    <h2>Регистрация участника</h2>
    <p class="t1">
      Сегодня в колесо фортуны играет <br />
      Хорошечный человек по имени и фамилии:
    </p>
    <div class="inputs">
      <the-input label="Имя (Опционально)" v-model="values.name" />
      <the-input label="Фамилия (опционально)" v-model="values.surname" />
      <the-input
        label="Телефон"
        :mask-options="phoneMask"
        :rules="rules.phone"
        v-model="values.phone"
      />
      <the-input
        label="Дата рождения"
        :mask-options="dateMask"
        hint="Возрастное ограничение 21+"
        :rules="rules.date"
        v-model="values.birthdate"
      />
    </div>
    <p class="t1">Пол</p>
    <div class="g">
      <label>
        <input
          type="radio"
          name="g"
          value="f"
          v-model="values.gender"
          checked
        />
        <i></i>
        <span>Женский</span>
      </label>
      <label>
        <input type="radio" name="g" value="m" v-model="values.gender" />
        <i></i>
        <span>Мужской</span>
      </label>
    </div>
    <p class="t1">Автограф</p>
    <signature-receiver ref="receiver" />
    <p class="agree">
      Ставя роспись, вы соглашаетесь с
      <a href="#">условиями&nbsp;использования</a>,
      <a href="#">политикой&nbsp;конфиденциальности</a>.
    </p>
    <button type="submit" class="yellowBtn" :disabled="!canSave">
      <span>ВПЕРЁД, К ПОБЕДЕ!</span>
    </button>
    <img class="gus" src="/assets/gus.png" alt="" />
  </form>
</template>

<style scoped lang="scss">
#form {
  width: 477px;
  padding: 32px;
  border-radius: 16px;
  margin-top: 100px;
  margin-left: 48px;
  position: relative;
  z-index: 20;

  @media (max-width: 768px) {
    width: 100%;
    margin: 120px 0 0;
    transition: opacity 0.2s ease;
    &.-prizes {
      opacity: 0;
      pointer-events: none;
    }
  }

  h2 {
    margin-bottom: 24px;
  }
  .t1 {
    margin-bottom: 16px;
  }
  .inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 4px;
    grid-column-gap: 16px;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  .g {
    display: grid;
    grid-template-columns: repeat(2, 135px);
    margin-bottom: 16px;

    label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      input {
        display: none;
        + i {
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid #b3babd;
          border-radius: 50%;
          transform: translateY(-3px);
        }
        &:checked + i {
          border: 6px solid var(--Gold);
        }
      }
    }
  }
  .agree {
    color: var(--Black, #060d1e);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 133.333%;
    letter-spacing: -0.4px;
    margin-bottom: 24px;
    a {
      color: #4865fe;
    }
  }
  .gus {
    position: absolute;
    bottom: -34px;
    right: -101px;
    width: 303px;
    @media (max-width: 768px) {
      width: 171px;
      right: -27px;
      bottom: 4px;
    }
  }
}
</style>
