<template>
  <div>
    <div id="fullscreener" @click="requestFullScreen"></div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({}),
  methods: {
    requestFullScreen() {
      document.documentElement.requestFullscreen();
    },
    message(string) {
      console.error(string);
      alert(string);
    },
  },
};
</script>
<style lang="scss">
#fullscreener {
  position: fixed;
  left: calc(50% - 50px);
  top: 0;
  width: 100px;
  height: 50px;
  z-index: 200;
}
</style>
