<script>
export default {
  name: "Presents",
  props: {
    prizes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  watch: {
    active(newValue) {
      this.$emit("change", newValue);
    },
  },
};
</script>

<template>
  <div id="presents" :class="{ active }">
    <div class="backdrop"></div>
    <button type="button" class="trigger" @click="active = !active">
      <span class="icon"></span>
      <span class="space">
        <span class="flag"></span>
      </span>
    </button>
    <div class="grid">
      <div v-for="prize in prizes" :key="prize.id" class="prize">
        <div class="tn">
          <img :src="prize.tn" alt="" />
        </div>
        <p>{{ prize.name }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#presents {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  @media (max-width: 768px) {
    z-index: 20;
    pointer-events: none;
    position: fixed;
    overflow: hidden;
  }

  .backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);
    transition: opacity 0.4s ease;
    @media (max-width: 768px) {
      position: fixed;
    }
  }

  .trigger {
    position: absolute;
    top: 116px;
    left: 577px;
    width: 68px;
    height: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0 8px 14px rgba(0, 0, 0, 0.25));
    background: none;
    border: none;

    &::before {
      display: block;
      content: "";
      position: absolute;
      width: 68px;
      height: 68px;
      z-index: 3;
      left: 0;
      top: 8px;
      border-radius: 34px;
      background: var(--black-80, #383d4b);
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 68px;
      height: 68px;
      z-index: 3;
      left: 0;
      top: 0;
      border-radius: 34px;
      border: 1px solid #fff;
      background: radial-gradient(
          78.38% 72.79% at 50% 50%,
          #fff 0%,
          #ffc425 51%,
          #f89600 100%
        ),
        #ffc425;
    }

    .icon {
      width: 32px;
      height: 32px;
      position: relative;
      z-index: 4;
      background: url("/assets/gift.svg") center no-repeat;
      background-size: contain;
    }

    .flag {
      width: 236px;
      height: 56px;
      position: absolute;
      left: 18px;
      top: 12px;
      overflow: hidden;
      transform-origin: 21px 22px;
      //transform: rotate(-60deg);
      transition: transform 0.8s ease;
      &::before,
      &::after {
        display: block;
        content: "";
        width: 212px;
        box-sizing: border-box;
        height: 44px;
        border-radius: 8px;
        position: absolute;
        left: -212px;
        top: 0;
        transition: left 0.8s ease;
      }

      &::before {
        background: var(--black-80, #383d4b);
        top: 5px;
      }
      &::after {
        border: 1px solid #fff;
        background: radial-gradient(
            78.38% 72.79% at 50% 50%,
            #fae8b8 0%,
            #ffc425 51%,
            #f89600 100%
          ),
          #f1e6be;
        content: "Сегодня в колесе";
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
        padding-top: 3px;
        align-items: center;
        color: var(--Black, #060d1e);
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 157.143%;
        text-transform: uppercase;
      }
    }

    @media (max-width: 768px) {
      left: 20px;
      top: 78px;
      z-index: 2;
      pointer-events: auto;
      &::before {
        top: 6px;
      }
    }
  }

  .grid {
    position: absolute;
    top: 208px;
    left: 600px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    @media (max-width: 768px) {
      left: 20px;
      grid-template-columns: repeat(2, 1fr);
      top: 180px;
      right: 20px;
      padding-bottom: 40px;
    }

    .prize {
      display: flex;
      flex-direction: column;
      width: 120px;
      justify-content: center;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.4s ease;

      .tn {
        background: linear-gradient(to bottom, #ffffff 0%, #a9a289 100%);
        padding: 3px;
        width: 120px;
        height: 120px;
        border-radius: 6px;
        margin-bottom: 4px;
        img {
          width: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: 4px;
        }
      }
      p {
        color: var(--White, #fff);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 108%;
        letter-spacing: -0.24px;
      }

      @media (max-width: 768px) {
        width: 100%;

        .tn {
          width: 100%;
          aspect-ratio: 1;
          height: auto;
        }
      }
    }
  }

  &.active {
    @media (max-width: 768px) {
      overflow: auto;
    }
    .backdrop {
      opacity: 0.7;
    }

    .trigger {
      .flag {
        transform: rotate(0deg);
        &::before,
        &::after {
          left: 0;
        }
      }
    }
    .grid {
      pointer-events: auto;
      .prize {
        opacity: 1;
        @for $i from 1 through 12 {
          &:nth-child(#{$i}) {
            transition-delay: #{(0.1 * ($i - 1))}s;
          }
        }
      }
    }
  }
}
</style>
