import axios from "axios";

export default {
  computed: {
    token() {
      return window.localStorage.getItem("token");
    },
  },
  methods: {
    transportRequest(method, uri, data = {}, controller = null) {
      const headers = { "X-Requested-With": "XMLHttpRequest" };
      this.messages = {};
      const url = uri.substr(0, 4) === "http" ? uri : "/api/" + uri;

      const axiosProps = {
        method,
        url,
        headers,
        data,
      };

      if (method.toLowerCase() === "get") {
        axiosProps.params = data;
      }
      if (controller !== null) {
        axiosProps.signal = controller;
      }

      return axios(axiosProps);
    },
  },
};
