<template>
  <div id="navbar">
    <img src="/assets/logo_w.svg" alt="Zatecky Gus" class="logo" />
    <div
      :class="{ trigger: true, '-opened': opened }"
      @click="opened = !opened"
    >
      <p class="t1">{{ username }}</p>
      <ul class="dd">
        <li @click="refresh">Обновить</li>
        <li @click="logout">Выход</li>
      </ul>
    </div>
  </div>
</template>
<script>
import transport from "../../mixins/transport.js";

export default {
  name: "GameNav",
  mixins: [transport],
  data() {
    return {
      username: null,
      opened: false,
    };
  },
  created() {
    if (!window.username) {
      this.transport("GET", "promoter/check").then((response) => {
        window.username = response.data.name;
        this.username = response.data.name;
      });
    } else this.username = window.username;
  },
  methods: {
    refresh() {
      if (confirm("Перезагрузить?")) window.location.reload();
    },
    logout() {
      if (confirm("Выйти?")) this.$router.push({ name: "Logout" });
    },
  },
};
</script>

<style scoped lang="scss">
#navbar {
  position: absolute;
  top: 32px;
  left: 28px;
  right: 28px;
  display: flex;
  justify-content: space-between;
  z-index: 50;

  @media (max-width: 768px) {
    top: 20px;
    left: 20px;
    right: 20px;
  }

  .logo {
    width: 56px;
    @media (max-width: 768px) {
      width: 47px;
    }
  }

  .trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &::before {
      display: block;
      content: "";
      width: 28px;
      height: 28px;
      background: url("/assets/goose.svg") center no-repeat;
      background-size: contain;
      margin-right: 8px;
    }

    p {
      margin: 0;
      color: #ffffff;
    }

    &::after {
      display: block;
      content: "";
      width: 8px;
      height: 7px;
      margin-left: 4px;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1_863)'%3E%3Cpath d='M0.600915 1.53569C0.668254 1.44801 0.765475 1.37534 0.882608 1.32514C0.999741 1.27493 1.13257 1.24899 1.26748 1.24999L6.7331 1.24998C6.86801 1.24899 7.00084 1.27493 7.11797 1.32513C7.2351 1.37534 7.33233 1.44801 7.39966 1.53569C7.46537 1.62004 7.5 1.71597 7.5 1.81366C7.5 1.91135 7.46537 2.00728 7.39966 2.09163L4.66748 5.65108C4.59919 5.73828 4.5017 5.81059 4.38467 5.86083C4.26764 5.91107 4.13513 5.9375 4.00029 5.9375C3.86545 5.9375 3.73294 5.91107 3.61591 5.86083C3.49888 5.81059 3.40139 5.73828 3.3331 5.65108L0.60029 2.09163C0.534677 2.00724 0.500157 1.91128 0.500267 1.81359C0.500377 1.7159 0.535112 1.61999 0.600915 1.53569Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1_863'%3E%3Crect width='8' height='6' fill='white' transform='translate(8 6.5) rotate(180)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .dd {
      display: none;
      position: absolute;
      right: 0;
      top: 32px;
      background: #ffffff;
      list-style: none;
      padding: 0;
      border-radius: 8px;
      overflow: hidden;
      text-align: right;

      li {
        padding: 8px 16px;
      }
    }

    &.-opened {
      &::after {
        transform: rotateX(180deg);
      }
      .dd {
        display: block;
      }
    }
  }
}
</style>
